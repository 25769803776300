export default function getDocDefinition(
    printParams,
    grids
  ) {
    const {
      PDF_HEADER_COLOR,
      PDF_INNER_BORDER_COLOR,
      PDF_OUTER_BORDER_COLOR,
      PDF_ODD_BKG_COLOR,
      PDF_EVEN_BKG_COLOR,
      PDF_HEADER_HEIGHT,
      PDF_ROW_HEIGHT,
      PDF_PAGE_ORITENTATION,
      PDF_WITH_CELL_FORMATTING,
      PDF_WITH_COLUMNS_AS_LINKS,
      PDF_SELECTED_ROWS_ONLY,
      PDF_WITH_HEADER_IMAGE,
      PDF_WITH_FOOTER_PAGE_COUNT,
      PDF_LOGO,
      PDF_COLUMN_FILTER_INDICATOR
    } = printParams;
  
    return (function() {
      grids.forEach(grid => {
        grid.data = getGridData(grid.gridApi, grid.columnIds);
      })

      const header = PDF_WITH_HEADER_IMAGE
        ? {
            image: "optum-logo",
            width: 120,
            alignment: "center",
            margin: [0, 10, 0, 10]
          }
        : null;
  
      const footer = PDF_WITH_FOOTER_PAGE_COUNT
        ? function(currentPage, pageCount) {
            return {
              text: currentPage.toString() + " of " + pageCount,
              margin: [20]
            };
          }
        : null;
  
      const pageMargins = [
        10,
        PDF_WITH_HEADER_IMAGE ? 70 : 20,
        10,
        PDF_WITH_FOOTER_PAGE_COUNT ? 40 : 10
      ];

      const fillColor = (rowIndex, node, columnIndex) => {
        if (rowIndex < node.table.headerRows) {
          return PDF_HEADER_COLOR;
        }
        return rowIndex % 2 === 0 ? PDF_ODD_BKG_COLOR : PDF_EVEN_BKG_COLOR;
      };
  
      const hLineWidth = (i, node) =>
        i === 0 || i === node.table.body.length ? 1 : 1;
  
      const vLineWidth = (i, node) =>
        i === 0 || i === node.table.widths.length ? 1 : 0;
  
      const hLineColor = (i, node) =>
        i === 0 || i === node.table.body.length
          ? PDF_OUTER_BORDER_COLOR
          : PDF_INNER_BORDER_COLOR;
  
      const vLineColor = (i, node) =>
        i === 0 || i === node.table.widths.length
          ? PDF_OUTER_BORDER_COLOR
          : PDF_INNER_BORDER_COLOR;
  
      let docDefintiion = {
        pageOrientation: PDF_PAGE_ORITENTATION,
        header,
        footer,
        content: [], // Populate content later
        images: {
          "optum-logo": PDF_LOGO
        },
        styles: {
          headerText: {
            fontSize: 12, 
            color: '#e77721'
          },
          myTable: {
            margin: [0, 0, 0, 0]
          },
          tableHeader: {
            bold: true,
            fontSize: 8,
            margin: [0, PDF_HEADER_HEIGHT / 4, 0, 0]
          },
          tableCell: {
            // margin: [0, 15, 0, 0]
          }
        },
        pageMargins
      };

      let counter = 0;
      grids.forEach(grid => {

        counter += 1;

        if (counter > 1) docDefintiion.content.push({ text: ' ' }); // Empty line
        docDefintiion.content.push({ text: grid.header, style: 'headerText' });
        docDefintiion.content.push({
          style: "myTable",
          table: {
            headerRows: grid.data.headerRows,
            widths: grid.data.widths,
            body: grid.data.body,
            heights: grid.data.heights
          },
          layout: {
            fillColor,
            hLineWidth,
            vLineWidth,
            hLineColor,
            vLineColor
          }
        });

      })
  
      return docDefintiion;
    })();

    function getGridData(agGridApi, columnIds) {
      const columnGroupsToExport = getColumnGroupsToExport(agGridApi);
  
      const columnsToExport = getColumnsToExport(agGridApi, columnIds);
  
      const widths = getExportedColumnsWidths(columnsToExport);
  
      const rowsToExport = getRowsToExport(columnsToExport, agGridApi);
  
      const body = columnGroupsToExport
        ? [columnGroupsToExport, columnsToExport, ...rowsToExport]
        : [columnsToExport, ...rowsToExport];

      const headerRows = columnGroupsToExport ? 2 : 1;
        
      const heights = rowIndex =>
        rowIndex < headerRows ? PDF_HEADER_HEIGHT : PDF_ROW_HEIGHT;

      return { headerRows, body, widths, heights };
    }
  
    function getColumnGroupsToExport(agGridApi) {
      let displayedColumnGroups = agGridApi.getAllDisplayedColumnGroups();
  
      let isColumnGrouping = displayedColumnGroups.some(col =>
        col.hasOwnProperty("children")
      );
  
      if (!isColumnGrouping) {
        return null;
      }
  
      let columnGroupsToExport = [];
  
      displayedColumnGroups.forEach(colGroup => {
        let isColSpanning = colGroup.children.length > 1;
        let numberOfEmptyHeaderCellsToAdd = 0;
  
        if (isColSpanning) {
          let headerCell = createHeaderCell(colGroup);
          columnGroupsToExport.push(headerCell);
          // subtract 1 as the column group counts as a header
          numberOfEmptyHeaderCellsToAdd--;
        }
  
        // add an empty header cell now for every column being spanned
        colGroup.displayedChildren.forEach(childCol => {
          let pdfExportOptions = getPdfExportOptions(childCol.getColId(), agGridApi);
          if (!pdfExportOptions || !pdfExportOptions.skipColumn) {
            numberOfEmptyHeaderCellsToAdd++;
          }
        });
  
        for (let i = 0; i < numberOfEmptyHeaderCellsToAdd; i++) {
          columnGroupsToExport.push({});
        }
      });
  
      return columnGroupsToExport;
    }
  
    function getColumnsToExport(agGridApi, columnIds) {
      let columnsToExport = [];
      // agGridApi.getAllDisplayedColumns().forEach(col => {
      agGridApi.getAllGridColumns().forEach(col => {
        if (!columnIds || columnIds.includes(col.colId)) {
          let pdfExportOptions = getPdfExportOptions(col.getColId(), agGridApi);
          if (pdfExportOptions && pdfExportOptions.skipColumn) {
            return;
          }
          let headerCell = createHeaderCell(col);
          columnsToExport.push(headerCell);
        }
      });
      return columnsToExport;
    }
  
    function getRowsToExport(columnsToExport, agGridApi) {
      let rowsToExport = [];
  
      agGridApi.forEachNodeAfterFilterAndSort(node => {
        if (PDF_SELECTED_ROWS_ONLY && !node.isSelected()) {
          return;
        }
        let rowToExport = columnsToExport.map(({ colId, field }) => {
          let cellValue = node.data[field];
          if (field.includes(".")) {
            cellValue = node.data[field.split(".")[0]][field.split(".")[1]];
          }
          let tableCell = createTableCell(cellValue, colId, agGridApi);
          return tableCell;
        });
        rowsToExport.push(rowToExport);
      });
  
      return rowsToExport;
    }
  
    function getExportedColumnsWidths(columnsToExport) {
      return columnsToExport.map(() => 100 / columnsToExport.length + "%");
    }
  
    function createHeaderCell(col) {
      let headerCell = {};
  
      let isColGroup = col.hasOwnProperty("children");

      if (isColGroup) {
        headerCell.text = col.providedColumnGroup.colGroupDef.headerName;
        headerCell.colSpan = col.children.length;
        headerCell.colId = col.groupId;
      } else {
        let headerName = col.colDef.headerName;
  
        if (col.sort) {
          headerName += ` (${col.sort})`;
        }
        if (col.filterActive && PDF_COLUMN_FILTER_INDICATOR) {
          headerName += ` [FILTERING]`;
        }
  
        headerCell.text = headerName;
        headerCell.colId = col.getColId();
        headerCell.field = col.colDef.field;
      }
  
      headerCell["style"] = "tableHeader";
  
      return headerCell;
    }
  
    function createTableCell(cellValue, colId, agGridApi) {
      const tableCell = {
        text: cellValue !== undefined ? cellValue : "",
        style: "tableCell"
      };
  
      const pdfExportOptions = getPdfExportOptions(colId, agGridApi);
  
      if (pdfExportOptions) {
        const { styles, createURL } = pdfExportOptions;
  
        if (PDF_WITH_CELL_FORMATTING && styles) {
          Object.entries(styles).forEach(
            ([key, value]) => (tableCell[key] = value)
          );
        }
  
        if (PDF_WITH_COLUMNS_AS_LINKS && createURL) {
          tableCell["link"] = createURL(cellValue);
          tableCell["color"] = "blue";
          tableCell["decoration"] = "underline";
        }
      }
  
      return tableCell;
    }
  
    function getPdfExportOptions(colId, agGridApi) {
      let col = agGridApi.getColumn(colId);
      return col.colDef.pdfExportOptions;
    }
  }
  