import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import getDocDefinition from "./docDefinition";
import logos from "../logos";

pdfMake.vfs = pdfFonts;

function printDoc(grids, fileName) {
  // This export system was modified from the example in this link: https://blog.ag-grid.com/exporting-ag-grid-to-pdf/
  console.log("Exporting to PDF...");

  const printParams = {
    PDF_HEADER_COLOR: "#f8f8f8",
    PDF_INNER_BORDER_COLOR: "#dde2eb",
    PDF_OUTER_BORDER_COLOR: "#babfc7",
    PDF_LOGO: logos.optumLogo,
    PDF_PAGE_ORITENTATION: "landscape",
    PDF_WITH_HEADER_IMAGE: true,
    PDF_WITH_FOOTER_PAGE_COUNT: true,
    PDF_HEADER_HEIGHT: 25,
    PDF_ROW_HEIGHT: 15,
    PDF_ODD_BKG_COLOR: "#fcfcfc",
    PDF_EVEN_BKG_COLOR: "#fff",
    PDF_WITH_CELL_FORMATTING: true,
    PDF_WITH_COLUMNS_AS_LINKS: true,
    PDF_SELECTED_ROWS_ONLY: false,
    PDF_COLUMN_FILTER_INDICATOR: false,
  };

  const blobToFile = async (arg) => {
    const { blob, fileName } = arg;
    let options = {
      suggestedName: `${fileName}.pdf`,
    };
    await window
      .showSaveFilePicker(options)
      .then(async (fileHandle) => await fileHandle.createWritable())
      .then(async (stream) => {
        await stream.write(blob);
        await stream.close();
      })
      .then(() => {
        alert(`File ${fileName} has been successfully downloaded.`);
      })
      .catch((err) => console.log("SaveFile aborted"));
  };

  const docDefinition = getDocDefinition(printParams, grids);
  const pdfDocGenerator = pdfMake.createPdf(docDefinition);
  pdfDocGenerator.getBlob(async (blob) => {
    console.log(blob);
    await blobToFile({ blob: blob, fileName: fileName });
  });
}

export default printDoc;
