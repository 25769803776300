import React, { Component } from "react";
// import {Spinner} from 'react-bootstrap';
import { CircularProgress } from "@mui/material";
export default class CustomLoadingOverlay extends Component {
  render() {
    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress disableShrink sx={{ color: "#e77721" }} size={60} />
      </div>
    );
  }
}
