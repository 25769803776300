import React, { useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

const ListItemBody = ({ config }) => {
    return (
        <>
            <ListItemIcon>{config.icon}</ListItemIcon>
            <ListItemText primary={config.title} />
        </>
    );
};

const MenuItem = ({ config, onExportClick }) => {
    return (
        <ListItem onClick={() => onExportClick(config.id, config.type)} style={{ paddingTop: 0, paddingBottom: 0 }} button>
            <ListItemBody config={config} />
        </ListItem>
    );
};

const ExpandableMenuItem = ({ config, onExportClick }) => {
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <div component="nav">
            <ListItem style={{ paddingTop: 0, paddingBottom: 0 }} button onClick={handleClick}>
                <ListItemBody config={config} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} unmountOnExit>
                <Menu items={config.items} onExportClick={onExportClick} />
            </Collapse>
        </div>
    );
};

export default function Menu({ items, onExportClick }) {
    const createList = (items, onExportClick2) => {
        let menu = [];
        items.map((menuItem) => {
            if (Array.isArray(menuItem.items) && menuItem.items.length > 0) {
                menu.push(<ExpandableMenuItem config={menuItem} key={menuItem.title} onExportClick={onExportClick2} />);
            } else {
                menu.push(<MenuItem config={menuItem} key={menuItem.title} onExportClick={onExportClick2} />);
            }
        });
        return menu.concat();
    };

    return <List style={{ padding: 0 }}>{createList(items, onExportClick)}</List>;
}
