import pjson from "../package.json";
import moment from "moment";
import localStorage from 'react-secure-storage';
const CryptoJS = require("crypto-js");

const post6c = async (obj) => {
  try {
    return await fetch(
      `${
        localStorage.getItem("apiUrl") || process.env.REACT_APP_API_URL
      }/dms/post6cLog`,
      {
        method: "post",
        headers: new Headers({
          "Content-Type": "application/json",
        }),
        body: JSON.stringify(obj),
      }
    );
  } catch (e) {
    console.log(e);
  }
};

const capitalizeFirstChar = (val) => {
  if (val === null) {
    return "";
  } else if (typeof val === "string" || val instanceof String) {
    let firstChar = val.slice(0, 1).toUpperCase();
    return firstChar + val.slice(1);
  } else if (typeof val === "boolean") {
    return val === true ? "True" : "False";
  } else {
    return val;
  }
};

const formatDateTime = (dateString) => {
  if (dateString) {
    return moment(dateString).format("YYYY-MM-DD hh:mm A z"); //tz('America/Chicago').
  } else {
    return "";
  }
};

const formatNumberWithComma = (num) => {
  if (!num) return "0";
  return String(num).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, "$1,");
};

const getDropdownValues = async (field) => {
  // console.log(JSON.parse(localStorage.getItem("dropdowns")));
  let dropdowns = JSON.parse(localStorage.getItem("dropdowns"));
  if (dropdowns && dropdowns.length > 0) {
    let dropdownValues = dropdowns.find(
      (dropdown) => dropdown.field === field
    ).values;
    return dropdownValues;
  } else {
    return [];
  }
};

let keySize = 256;
let ivSize = 128;
let iterations = 100;

const encrypt = (msg, pass) => {
  let salt = CryptoJS.lib.WordArray.random(128 / 8);

  let key = CryptoJS.PBKDF2(pass, salt, {
    keySize: keySize / 32,
    iterations: iterations,
  });

  let iv = CryptoJS.lib.WordArray.random(ivSize / 8);

  let encrypted = CryptoJS.AES.encrypt(msg, key, {
    iv: iv,
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC,
    hasher: CryptoJS.algo.SHA256,
  });

  // salt, iv will be hex 32 in length
  // append them to the ciphertext for use  in decryption
  let transitmessage = salt.toString() + iv.toString() + encrypted.toString();
  return transitmessage;
};

const decrypt = (transitmessage, pass) => {
  let salt = CryptoJS.enc.Hex.parse(transitmessage.substr(0, 32));
  let iv = CryptoJS.enc.Hex.parse(transitmessage.substr(32, 32));
  let encrypted = transitmessage.substring(64);

  let key = CryptoJS.PBKDF2(pass, salt, {
    keySize: keySize / 32,
    iterations: iterations,
  });

  let decrypted = CryptoJS.AES.decrypt(encrypted, key, {
    iv: iv,
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC,
    hasher: CryptoJS.algo.SHA256,
  });
  return decrypted;
};

const generateToken = async () => {
  let date = moment().utc().format();
  try {
    let obj = { appName: pjson.name, timeStamp: date, u: localStorage.getItem('u') ?? '' };
    let payload = JSON.stringify(obj);
    let secret = process.env.REACT_APP_DMS_APP_SECRET;
    let encrypted = encrypt(payload, secret);
    return encrypted;
  } catch (e) {
    console.log(e);
    return false;
  }
};

export {
  post6c,
  capitalizeFirstChar,
  formatDateTime,
  formatNumberWithComma,
  getDropdownValues,
  generateToken,
};
