import React from "react";
import MaskedInput from "react-text-mask";
import PropTypes from "prop-types";
import { TextField } from "@mui/material";

const TextMaskCustom = (props) => {
  const { inputRef, ...other } = props;

  return (
    (<MaskedInput
      {...other}
      ref={inputRef}
      mask={[
        /[1-9]/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={"\u2000"}
    />)
  );
};

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

const MaskedTextfield = (props) => {
  console.log(props);
  return (
    <TextField
      id="phone"
      label="Phone"
      InputProps={{
        inputComponent: TextMaskCustom,
        value: props.value,
        onChange: props.onChange,
      }}
      style={{ width: "100%", paddingLeft: 5, paddingRight: 5, marginTop: 20 }}
    />
  );
};

export default MaskedTextfield;
