import React from "react";
import { Container } from "react-bootstrap";
import {
  Button,
  Card,
  Typography,
  CardContent,
  CardActions,
  Divider,
  CardHeader,
} from "@mui/material";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import localStorage from "react-secure-storage";

const TOU = (props) => {
  const navigate = useNavigate();
  const handleAccept = () => {
    console.log(JSON.parse(localStorage.getItem("externalUserInfo")));
    let user = JSON.parse(localStorage.getItem("externalUserInfo"));
    let params = {
      _id: user._id,
      touAccepted: true,
    };
    console.log(params);
    fetch(`${localStorage.getItem("apiUrl")}/postUpdateExternalUser`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(params),
    })
      .then((res) => res.json())
      .then((res) => {
        navigate("/in/");
      })
      .catch(console.log);
  };

  const handleReject = () => {
    localStorage.clear();
    navigate("/");
  };

  const clickHandler = (e) => {
    e.preventDefault();
    if (e.target.name === "privacyView") {
      window.open(
        `${process.env.PUBLIC_URL}/Online Services Privacy Policy.pdf`,
        "PrivacyPolicyViewer",
        "height=540,width=960,location=off,scrollbars=on,status=off,resizable=on"
      ); //PrivacyPolicyViewer
    } else {
      window.open(
        `${process.env.PUBLIC_URL}/Online Services Terms of Use.pdf`,
        "TermsOfUseViewer",
        "height=540,width=960,location=off,scrollbars=on,status=off,resizable=on"
      ); //TermsOfUseViewer
    }
  };

  return (
    <Container
      fluid
      style={{
        placeContent: "center",
        alignItems: "center",
        flexDirection: "row",
        boxSizing: "border-box",
        display: "flex",
        marginTop: "10%",
      }}
    >
      <div
        style={{
          flexGrow: 1,
          flexShrink: 1,
          flexBasis: "100%",
          boxSizing: "border-box",
          maxWidth: "80%",
        }}
      >
        <div>
          <div
            style={{
              flexDirection: 1,
              flexWrap: "wrap",
              boxSizing: "border-box",
              display: "flex",
              placeContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ flexBasis: "20%", padding: 25 }}>
              <img
                src={`${localStorage.getItem(
                  "apiUrl"
                )}/getHit?c=TOU&u=${localStorage.getItem("u")}
                &name=${localStorage.getItem("displayName")}
                &utype=${localStorage.getItem("userType")}
                &vendor=${localStorage.getItem("vendorName")}
                &client=${localStorage.getItem("clientName")}`}
                alt=""
                style={{ display: "none" }}
              />
              <Card
                sx={{
                  width: 600,
                  height: 250,
                  margin: "auto",
                }}
                elevation={3}
              >
                <CardHeader
                  style={{
                    color: "#e77721",
                    padding: 5,
                    textAlign: "center",
                    fontSize: "30px",
                    marginTop: 20,
                  }}
                  titleTypographyProps={{ fontWeight: "bold" }}
                  title=" Terms of Use and Privacy Policy"
                ></CardHeader>
                <div
                  style={{
                    flexWrap: "wrap",
                    boxSizing: "border-box",
                    display: "flex",
                    placeContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Divider
                    orientation="horizontal"
                    style={{
                      borderColor: "#404040",
                      borderBottomWidth: "2px",
                      marginTop: 10,
                      width: "80%",
                      alignContent: "center",
                    }}
                  />
                </div>
                <CardContent
                  style={{ height: 50, marginTop: 20, marginBottom: 10 }}
                >
                  <Typography
                    sx={{
                      textAlign: "center",
                      fontSize: 16,
                      fontWeight: "bold",
                      color: "#888",
                    }}
                  >
                    I have reviewed and agree to the{" "}
                    <Link name="touView" onClick={clickHandler}>
                      Terms of Use
                    </Link>{" "}
                    and{" "}
                    <Link href="#" name="privacyView" onClick={clickHandler}>
                      Privacy Policy
                    </Link>
                  </Typography>
                </CardContent>
                <CardActions>
                  <div
                    style={{
                      margin: "0 auto",
                      width: "40%",
                      textAlign: "center",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="warning"
                      sx={{
                        fontWeight: "bold",
                        fontSize: 12,
                        padding: "10px 15px",
                      }}
                      onClick={handleAccept}
                      style={{ margin: 10 }}
                    >
                      I Agree
                    </Button>

                    <Button
                      variant="contained"
                      color="error"
                      sx={{
                        fontWeight: "bold",
                        fontSize: 12,
                        padding: "10px 15px",
                      }}
                      onClick={handleReject}
                      style={{ margin: 10 }}
                    >
                      Cancel
                    </Button>
                  </div>
                </CardActions>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default TOU;
