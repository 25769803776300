import { Outlet, Link } from "react-router-dom";
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Alert, Navbar, Nav, NavDropdown, Container} from 'react-bootstrap' 
import { LinkContainer } from "react-router-bootstrap";
import { post6c } from './utils';
import NavBarTop from "./NavBarTop";
import moment from 'moment-timezone'
import localStorage from 'react-secure-storage';
// import NavBarBottomHome from "./NavBarBottomHome";

const Layout = () => {
    console.log(localStorage)
    console.log(JSON.parse(localStorage.getItem('user')))
    console.log(localStorage.getItem('loginExpiration'))
    console.log(localStorage.getItem('smsApiToken'))
    console.log(JSON.parse(localStorage.getItem('externalUserInfo')))
    console.log(localStorage.getItem('vendorName'))
    console.log(moment().isBefore(new Date(localStorage.getItem('loginExpiration'))))
    if (!JSON.parse(localStorage.getItem('auth')) || moment().isAfter(localStorage.getItem('loginExpiration'))) {
        console.log(localStorage.getItem('loginExpiration'))
        localStorage.clear()
        window.location.replace('/login')
    }
    const titleClick=(e)=>{
        console.log('title was clicked')
    }
    const handleLogoff = ()=>{
        let forPosting={
            "product": "JADEPLUS",
            "class": "SECURITY_AUDIT",
            "sourceUserMSID": localStorage.getItem('u')?localStorage.getItem('u'):'User unknown',
            "message": "TerminateUserSession:SUCCESS",
            "severity": "INFO"
          }
        post6c(forPosting).then(console.log).catch(console.log)
        localStorage.clear()
        window.location.replace('/')
    }
    return (
        <>
        <NavBarTop />
         <Outlet />
         {/* <NavBarBottomHome /> */}
        </>
  )
};

export default Layout;