import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { HashRouter, BrowserRouter, Routes, Route } from "react-router-dom"; //BrowserRouter,
import Login from "./Login";
import Layout from "./Layout";
import Home from "./Home";
import Landing from "./Landing";
import PublicLayout from "./PublicLayout";
import Dashboard from "./Dashboard";
import Search from "./Search";
import ChartCodingDetail from "./ChartCodingDetail";
// import Admin from "./Admin";
import Registration from "./Registration";
import Auth from "./Auth";
import TOU from "./TOU";
import { generateToken } from "./utils";

var originalFetch = window.fetch;
window.fetch = async function (input, init) {
  if (input === `${process.env.REACT_APP_AUTH_URL}/dms/aaa`) {
    return originalFetch(input, init);
  }
  if (!init) {
    init = {};
  }
  if (!init.headers) {
    init.headers = new Headers();
  }
  const token = await generateToken();
  // console.log('yo what is the input bro => ', input)
  // console.log('yo is the token changing bro => ', token)
  // init.headers could be:
  //   `A Headers object, an object literal,
  //    or an array of two-item arrays to set request’s headers.`
  if (init.headers instanceof Headers) {
    init.headers.append("Authorization", `Bearer ${token}`);
  } else if (init.headers instanceof Array) {
    init.headers.push(["Authorization", `Bearer ${token}`]);
  } else {
    // object ?
    init.headers["Authorization"] = `Bearer ${token}`;
  }
  return originalFetch(input, init);
};

function App() {
  return (
    <div style={{ paddingTop: 60, paddingBottom: 10 }}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<PublicLayout />}>
            <Route index element={<Landing />} />
            <Route path="/login" element={<Login />} />
            <Route path="/auth" element={<Auth />} />
            <Route path="/registration" element={<Registration />} />
            <Route path="/tou" element={<TOU />} />
          </Route>

          <Route path="/in/" element={<Layout />}>
            <Route index element={<Dashboard />} />
            <Route path="dashboard" element={<Dashboard />} />
            {/* <Route path="search" element={<Search />} /> */}
            <Route path="search" element={<Search />} />
            {/* <Route path="chartCodingDetail" element={<ChartCodingDetail />} /> */}
            {/* <Route path="admin" element={<Admin />} /> */}
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
