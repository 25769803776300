import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import pjson from "../package.json";
import _ from "underscore";
import { Card, Spinner, Container, Row, Col } from "react-bootstrap";
import { generateToken } from "./utils";
import localStorage from "react-secure-storage";

const Auth = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  console.log(location.search);

  useEffect(() => {
    const handleAuth = async () => {
      let searchParams = new URLSearchParams(location.search);
      console.log(searchParams.get("code"));
      console.log(searchParams.has("code"));
      if (
        !location.search ||
        moment().isAfter(localStorage.getItem("loginExpiration"))
      ) {
        localStorage.clear();
        window.location.assign(
          process.env.REACT_APP_OIDC_AUTH_URL + "&prompt=login"
        );
      } else if (
        JSON.parse(localStorage.getItem("auth")) &&
        localStorage.getItem("auth") === "true" &&
        moment().isBefore(localStorage.getItem("loginExpiration"))
      ) {
        window.location.assign("/in/");
      } else if (searchParams?.get("error")) {
        console.log("ooops error in logging in to OHID");
        console.log(searchParams.get("error_description"));
        alert(searchParams.get("error_description"));
        console.log(searchParams);
        localStorage.clear();
        window.location.assign(
          process.env.REACT_APP_OIDC_AUTH_URL + "&prompt=login"
        );
      } else {
        //this is where you receive code as a query parameter
        console.log(searchParams?.get("code")); //need to make a call to userFromCode
        let code = searchParams?.get("code");
        let token = await generateToken();
        localStorage.setItem("token", token);
        try {
          let userFromCode = await fetch(
            `${process.env.REACT_APP_PROXY_URL}/getUserFromCode?code=${code}`
          );
          let userFromCodeJson = await userFromCode.json();
          console.log("userFromCodeJson => ", userFromCodeJson);

          if (userFromCodeJson.user) {
            //set dropdown localstorage
            //use user info to check against externalUsers
            let externalUser = await fetch(
              `${process.env.REACT_APP_API_URL}/getXUsers?idp=OHID&preferred_username=${userFromCodeJson.user.preferred_username}`
            );
            let externalUserJson = await externalUser.json();
            console.log("externalUserJson => ", externalUserJson);
            if (externalUserJson.rows.length > 0) {
              //continue on but wait we'd have to set localstorage
              if (externalUserJson.rows[0].status === "approved") {
                await setDropdowns(externalUserJson.rows[0]);
                await setLocalStorage(externalUserJson.rows[0]);
                let r = await fetch(
                  localStorage.getItem("apiUrl") +
                    `/getRatings?user=${localStorage.getItem(
                      "u"
                    )}&appName=Chart3p External`
                );
                let rj = await r.json();
                if (rj && rj.rows && rj.rows.length > 0) {
                  //already has entry in ratings
                  console.log("already has entry in ratings");
                  localStorage.setItem(
                    "appRating",
                    JSON.stringify(rj.rows[0].rating)
                  );
                }
                if (externalUserJson.rows[0].touAccepted === true) {
                  navigate("/in/dashboard");
                } else {
                  navigate("/tou");
                }
              } else if (externalUserJson.rows[0].status === "declined") {
                const navState = {
                  message:
                    "Registration was declined. Please reach out to the Client if you feel you have received this message in error. This request has been fulfilled by Optum on behalf of the Client.",
                  success: false,
                };
                localStorage.clear();
                navigate("/", { state: navState });
              } else {
                const navState = {
                  message:
                    "Registration has been submitted, please wait for the registration process to be completed.",
                  success: true,
                };
                localStorage.clear();
                navigate("/", { state: navState });
              }
            } else {
              await setDropdowns(null);
              //forward to registration
              navigate("/registration", { state: userFromCodeJson.user });
            }
          } else {
            console.log("ooops could not find user in getUserFromCode");
            alert(
              "We apologize for the inconvenience. We encountered an issue while authorizing your OHID login information."
            );
            localStorage.clear();
            // window.location.assign(process.env.REACT_APP_OIDC_AUTH_URL + "&prompt=login");
            navigate("/");
          }
        } catch (err) {
          console.log('"ooops error from getUserFromCode', err);
          alert(
            "We apologize for the inconvenience. We encountered an issue while authorizing your OHID login information."
          );
          localStorage.clear();
          window.location.assign(
            process.env.REACT_APP_OIDC_AUTH_URL + "&prompt=login"
          );
        }
      }
    };
    handleAuth();
  }, []);

  const setLocalStorage = async (user) => {
    try {
      localStorage.setItem("auth", true);
      localStorage.setItem("environment", process.env.REACT_APP_ENV);
      localStorage.setItem(
        "navHeader",
        process.env.REACT_APP_ENV !== "Prod" ? process.env.REACT_APP_ENV : ""
      );
      localStorage.setItem("apiUrl", process.env.REACT_APP_API_URL);
      localStorage.setItem("loginExpiration", moment().add(3, "hours"));
      localStorage.setItem("appDescription", pjson.description);
      localStorage.setItem("appVersion", pjson.version);
      localStorage.setItem("displayName", user.name);
      localStorage.setItem("u", user.preferred_username);
      localStorage.setItem("userkey", user.preferred_username);
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("userEmail", user.email);
      localStorage.setItem("externalUserInfo", JSON.stringify(user));
      localStorage.setItem("userType", user.userType);
      localStorage.setItem("isAdmin", user.isAdmin);
      localStorage.setItem("vendorName", user.vendorName);
      localStorage.setItem("clientName", user.clientName);
    } catch (e) {
      console.log(e);
    }
  };

  const setDropdowns = async (user) => {
    try {
      let res;
      if (user) {
        res = await fetch(
          `${process.env.REACT_APP_API_URL}/getDropdowns2?${
            user.userType === "vendor" ? "chartSource" : "clientCode"
          }=${user.userType === "vendor" ? user.vendorName : user.clientName}`
        );
      } else {
        res = await fetch(`${process.env.REACT_APP_API_URL}/getDropdowns2`);
      }
      let resj = await res.json();
      console.log("dropDownResj => ", resj);
      if (resj.rows.length > 0) {
        localStorage.setItem("dropdowns", JSON.stringify(resj.rows));
        return resj.rows[0];
      } else {
        localStorage.setItem("dropdowns", JSON.stringify([]));
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Container
      fluid
      style={{
        paddingLeft: "0px",
        paddingRight: "0px",
        width: "98%",
        overflowX: "hidden",
        overflowY: "auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <Container
        fluid
        className="text-center fluid justify-content-center"
        style={{ width: "100%" }}
      >
        <Row>
          <Col>&emsp;</Col>
        </Row>
        <Row>
          <Col lg={4}></Col>
          <Col lg={4}>
            <Card className="justify-content-center" style={{ width: "480px" }}>
              <Card.Header className="text-center">
                <Spinner animation="border" variant="primary" />
                <Spinner animation="border" variant="secondary" />
                <Spinner animation="border" variant="warning" />
                <h5>Loading...</h5>
              </Card.Header>
              <Card.Body>
                <Card.Text className="text-center">
                  Please wait while the system authorizes you for CV use...
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4}></Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Auth;
