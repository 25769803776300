import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Alert, Navbar, Nav, NavDropdown, Container} from 'react-bootstrap' 
import { Link } from 'react-router-dom'
import { LinkContainer } from "react-router-bootstrap";
import { post6c } from './utils';
import localStorage from 'react-secure-storage';

const NavBarTopPublic = (props) => {
    const titleClick=(e)=>{
        console.log('title was clicked')
    }
    const handleLogoff = ()=>{
        let forPosting={
            "product": "JADEPLUS",
            "class": "SECURITY_AUDIT",
            "sourceUserMSID": localStorage.getItem('u')?localStorage.getItem('u'):'User unknown',
            "message": "TerminateUserSession:SUCCESS",
            "severity": "INFO"
          }
        post6c(forPosting).then(console.log).catch(console.log)
        localStorage.clear()
        window.location.replace('/')
    }
    const handleLogin=()=>{
        window.location.replace('/in/')
    }
    return ( 
        <Navbar  variant="dark" bg='dark' expand fixed='top' style={{paddingTop:'0px', paddingBottom:'0px', margin:'0px'}} >
            
            <Navbar.Brand ><Link to='/' onClick={titleClick} style={{textDecoration:'none'}}><h4 style={{color:'orange', paddingLeft:'10px', margin:'0px', textDecoration:'none'}}><img src={process.env.PUBLIC_URL + '/OptumO.png'} valign='center' height='32px' alt='Optumlogo' title={localStorage.getItem('appDescription') + ' v' + localStorage.getItem('appVersion')} style={{textDecoration:'none'}}/>&ensp;Chart3P Coding</h4></Link></Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
            {/* <Nav fill>
                <NavDropdown title="Order Services" id="SMS-nav-dropdown">
                    <NavDropdown.Item href="/in/orderTalend">Talend</NavDropdown.Item>
                    <NavDropdown.Item href="/in/orderRundeck">Rundeck </NavDropdown.Item>
                    <NavDropdown.Item href="/in/orderMongoDB">MongoDB</NavDropdown.Item>
                    <NavDropdown.Item href="/in/orderAtlassian">Atlassian Tools</NavDropdown.Item>
                    <NavDropdown.Item href="/in/orderKnime">Knime</NavDropdown.Item>
                    <NavDropdown.Item href="/in/orderStudio3T">Studio-3T</NavDropdown.Item>
                </NavDropdown>
            </Nav> */}
            <Container style={{paddingRight:'0px', marginRight:'0px'}}>
                <Nav style={{textAlign:'right',justifyContent:'end'}}>
                    {/* <Nav.Link className='mr-sm-2' eventKey={7} style={{textAlign:'right'}}  onClick={handleLogin}>{localStorage.getItem('displayName')} &ensp; Login {localStorage.getItem('navHeader')}</Nav.Link> */}
                    <Navbar.Brand  className="mr-sm-2" href="#home" style={{textAlign:'right', marginRight:10}}>&ensp;<img src={process.env.PUBLIC_URL + '/optum-logo-dark-color.png'} height='32px' alt='Optumlogo' title={localStorage.getItem('appDescription') + ' v' + localStorage.getItem('appVersion')}/></Navbar.Brand>
                </Nav>
            </Container>
            </Navbar.Collapse>
        </Navbar>
     );
}
 
export default NavBarTopPublic;