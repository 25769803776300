import React, { Component } from "react";

export default class CustomNoRowsOverlay extends Component {
  render() {
    return (
      <div
        className="ag-overlay-loading-center"
        style={{ backgroundColor: "#FFA500", height: "9%" }}
      >
        <i className="far fa-frown">No Data Found.</i>
      </div>
    );
  }
}
