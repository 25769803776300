import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import {
  Alert,
  Button,
  Snackbar,
  Autocomplete,
  TextField,
} from "@mui/material";
import { getDropdownValues } from "./utils";
import MaskedInput from "react-text-mask";
import PropTypes from "prop-types";
import MaskedTextfield from "./MaskedTextfield";
import localStorage from 'react-secure-storage';

const Registration = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location.state);
  // console.log(location.state.searchParams?.get('code'))
  console.log(props);
  console.log(JSON.parse(localStorage.getItem("user")));
  let user = JSON.parse(localStorage.getItem("user"));
  const [state, setState] = useState({
    given_name:
      props.data?.givenName || user?.givenName || location.state?.given_name,
    family_name: props.data?.sn || user?.sn || location.state?.family_name,
    ohid: props.data?.cn || user?.cn || location.state?.preferred_username,
    email: props.data?.mail || user?.mail || location.state?.email,
    userTypeOptions: ["vendor", "client"],
    userType: null,
    clientOptions: [],
    vendorOptions: [],
    vendorName: null,
    clientName: null,
    phone: "",
    idp: user ? "Optum AD" : "OHID",
    alertWarning: null,
    ohidUser: location.state,
  });

  // useEffect(() => {
  //   const fetchDropdownData = async () => {
  //     try {
  //       let vendorOptions = await getDropdownValues("vendor");
  //       let clientOptions = await getDropdownValues("client");
  //       await setState((p) => ({
  //         ...p,
  //         vendorOptions,
  //         clientOptions,
  //       }));
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   };
  //   fetchDropdownData();
  // }, []);

  const onCloseAlertWarning = () => {
    setState((p) => ({
      ...p,
      alertWarning: null,
    }));
  };

  const handleClear = (e) => {
    setState((p) => ({
      ...p,
      given_name: props.data?.givenName || user.givenName,
      family_name: props.data?.sn || user.sn,
      ohid: props.data?.cn || user.cn,
      email: props.data?.mail || user.mail,
      userTypeOptions: ["vendor", "client"],
      userType: "",
      vendorName: "",
      clientName: null,
      phone: "",
      vendorOptions: [],
      clientOptions: [],
    }));
  };

  const onChangeVendor = (e, val) => {
    setState((s) => ({ ...s, vendorName: val }));
  };

  const onChangeClient = (e, val) => {
    setState((s) => ({ ...s, clientName: val }));
  };

  const onChangeType = async (e, val) => {
    if (val === "vendor") {
      let vendorOptions = await getDropdownValues("vendor");
      await setState((p) => ({
        ...p,
        vendorOptions,
        userType: val,
        clientOptions: [],
        vendorName: null,
        clientName: null,
      }));
    } else if (val === "client") {
      let clientOptions = await getDropdownValues("client");
      await setState((p) => ({
        ...p,
        clientOptions,
        userType: val,
        vendorOptions: [],
        vendorName: null,
        clientName: null,
      }));
    } else {
      await setState((p) => ({
        ...p,
        userType: val,
        vendorOptions: [],
        clientOptions: [],
        vendorName: null,
        clientName: null,
      }));
    }
  };

  const handleTextfieldChange = (e) => {
    console.log(e.target.id);
    console.log(e.target.value);
    setState((state) => ({ ...state, [e.target.id]: e.target.value }));
  };

  const TextMaskCustom = (props) => {
    const { inputRef, ...other } = props;

    return (
      <MaskedInput
        {...other}
        ref={inputRef}
        mask={[
          /[1-9]/,
          /\d/,
          /\d/,
          "-",
          /\d/,
          /\d/,
          /\d/,
          "-",
          /\d/,
          /\d/,
          /\d/,
          /\d/,
        ]}
        placeholderChar={"\u2000"}
      />
    );
  };

  TextMaskCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
  };

  const isValidUser = (stateData) => {
    const { phone, vendorName, clientName, userType } = stateData;
    if (userType === "vendor") {
      if (!vendorName) {
        setState((s) => ({ ...s, alertWarning: "Vendor Name is required." }));
        return false;
      }
    } else {
      if (!clientName) {
        setState((s) => ({ ...s, alertWarning: "Client Name is required." }));
        return false;
      }
    }
    if (!phone) {
      setState((s) => ({ ...s, alertWarning: "Phone number is required." }));
      return false;
    }
    if (phone.includes("_")) {
      setState((s) => ({ ...s, alertWarning: "Invalid phone number." }));
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!isValidUser(state)) return;
    let params = {
      preferred_username: state.ohid,
      family_name: state.family_name,
      given_name: state.given_name,
      email: state.email,
      phone: state.phone,
      userType: state.userType,
      vendorName: state.vendorName,
      clientName: state.clientName,
      idp: state.idp,
      // active: true, //the fact that user is in this page makes user active
      userId: location.state ? state.ohid : localStorage.getItem("u"),
      // isAdmin: false,
      status: "pending",
    };
    console.log(params);
    if (location.state) {
      //then this is an OHID user
      params = { ...params, ...location.state };
    }
    //postNewExternalUser
    fetch(`${process.env.REACT_APP_API_URL}/postNewExternalUser`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(params),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        console.log(res.success);
        if (res.success) {
          const navState = {
            message:
              "Registration submitted successfully. Please wait for approval.",
            success: true,
          };
          navigate("/", { state: navState });
        } else {
          setState((s) => ({ ...s, alertWarning: res.error }));
          console.error(res);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const hideRegistration = () => {
    if (location.state) {
      //then this is an OHID user
      navigate("/", {});
    } else {
      navigate("/login", {});
    }
  };

  return (
    <Container
      fluid
      style={{
        paddingLeft: "0px",
        paddingRight: "0px",
        width: "98%",
        overflowX: "hidden",
        overflowY: "auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <Snackbar
        open={state.alertWarning !== null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        onClose={onCloseAlertWarning}
      >
        <Alert
          variant="filled"
          onClose={onCloseAlertWarning}
          severity="warning"
        >
          {state.alertWarning}
        </Alert>
      </Snackbar>
      <div style={{ width: "75%" }}>
        <div style={{ textAlign: "center", width: "100%" }}>
          <h3 style={{ color: "#e77721", padding: 20 }}>Registration Form</h3>
        </div>
        <Row>
          <Col xs={12}>
            <Row className="m-0 p-0" style={{ height: "100%" }}>
              <div
                style={{
                  height: "100%",
                  border: "2px solid #e77721",
                  borderRadius: 30,
                  position: "relative",
                  width: "100%",
                  textAlign: "center",
                  padding: 50,
                }}
              >
                <p
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    color: "#888",
                    padding: 5,
                  }}
                >
                  Please fill in the form below to register for Chart3P Coding.
                </p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <TextField
                    id="ohid"
                    label="Account ID"
                    style={{
                      width: "100%",
                      paddingLeft: 5,
                      paddingRight: 5,
                      marginTop: 20,
                    }}
                    onChange={handleTextfieldChange}
                    value={state.ohid}
                    disabled
                  />
                  <TextField
                    id="given_name"
                    label="First Name"
                    style={{
                      width: "100%",
                      paddingLeft: 5,
                      paddingRight: 5,
                      marginTop: 20,
                    }}
                    onChange={handleTextfieldChange}
                    value={state.given_name}
                    disabled
                  />
                  <TextField
                    id="family_name"
                    label="Last Name"
                    style={{
                      width: "100%",
                      paddingLeft: 5,
                      paddingRight: 5,
                      marginTop: 20,
                    }}
                    onChange={handleTextfieldChange}
                    value={state.family_name}
                    disabled
                  />

                  <TextField
                    id="email"
                    label="Email"
                    style={{
                      width: "100%",
                      paddingLeft: 5,
                      paddingRight: 5,
                      marginTop: 20,
                    }}
                    onChange={handleTextfieldChange}
                    value={state.email}
                    disabled
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <MaskedTextfield
                    onChange={handleTextfieldChange}
                    value={state.phone}
                  ></MaskedTextfield>
                  <Autocomplete
                    options={state.userTypeOptions}
                    getOptionLabel={(option) => option}
                    isOptionEqualToValue={(option, value) => option === value}
                    value={state.userType}
                    onChange={onChangeType}
                    style={{
                      marginTop: 20,
                      width: "100%",
                      paddingLeft: 5,
                      paddingRight: 5,
                    }}
                    // sx={{
                    //   "& .MuiOutlinedInput-root": {
                    //     padding: "1px",
                    //   },
                    // }}
                    renderInput={(params) => (
                      <TextField {...params} label="User Type" />
                    )}
                  />
                  {state.userType === "vendor" ? (
                    <Autocomplete
                      options={state.vendorOptions}
                      getOptionLabel={(option) => option}
                      isOptionEqualToValue={(option, value) => option === value}
                      value={state.vendorName}
                      onChange={onChangeVendor}
                      style={{
                        marginTop: 20,
                        width: "100%",
                        paddingLeft: 5,
                        paddingRight: 5,
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Vendor Name" />
                      )}
                    />
                  ) : (
                    <Autocomplete
                      options={state.clientOptions}
                      getOptionLabel={(option) => option}
                      isOptionEqualToValue={(option, value) => option === value}
                      value={state.clientName}
                      onChange={onChangeClient}
                      style={{
                        marginTop: 20,
                        width: "100%",
                        paddingLeft: 5,
                        paddingRight: 5,
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Client Name" />
                      )}
                    />
                  )}
                </div>

                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    marginTop: 20,
                  }}
                >
                  <Button
                    variant="contained"
                    color="warning"
                    style={{
                      width: "10%",
                      minWidth: "10%",
                      margin: 10,
                    }}
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    style={{
                      width: "10%",
                      minWidth: "10%",
                      margin: 10,
                      backgroundColor: "white",
                      color: "black",
                      textTransform: "none",
                      fontWeight: "bold",
                    }}
                    onClick={handleClear}
                  >
                    Reset
                  </Button>
                  <Button
                    variant="contained"
                    color="warning"
                    onClick={hideRegistration}
                    style={{
                      width: "10%",
                      minWidth: "10%",
                      margin: 10,
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </Row>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default Registration;
